import { useState } from "react";
import Slider from "react-slick";
import img from "../data/data.json";
import address from "../data/address.json";
import "./style/soon.scss";

export default function Soon() {
  const [slider1, setSlider1] = useState();
  const [slider2, setSlider2] = useState();
  const setting = {
    autoplay: true,
    fade: true,
    dots: true,
    speed: 500,
    arrows: false,
    infinite: true,
    asNavFor: slider2,
    autoplaySpeed: 4000,
  };

  const settingbg = {
    fade: true,
    dots: false,
    speed: 500,
    arrows: false,
    infinite: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <div className="">
        <header className="absolute px-[10%] z-20 top-5 flex items-center md:px-[6%] md:top-4">
          <img
            src="./images/logo.png"
            className="w-16 lg:w-12"
            alt="logo aura alam auraalam"
          />
          <p className="text-xl pt-2 pl-4 font-bold text-[#49F715]">
            Aura Alam
          </p>
        </header>

        <div className="relative md:pt-36">
          <div className="px-[10%] flex justify-center items-center min-h-screen space-x-5 bg-white/50 md:flex-col md:px-[6%]">
            <div className="relative z-10 w-1/2 text-white md:w-full md:pb-20">
              <h1 className="text-7xl font-bold sm:text-4xl">Segera Hadir!</h1>
              <h2 className="text-xl pt-7 pb-10 md:text-base">
                Untuk kemudahan Anda dalam berbelanja di Toko Aura Alam
              </h2>
            </div>

            <div className="relative z-10 w-1/2 md:w-[80%] md:!mx-auto">
              <Slider {...setting} ref={(slider) => setSlider1(slider)}>
                {img.map((e, idx) => (
                  <div key={idx} className="relative h-full w-full">
                    <img
                      src={e.img}
                      alt=""
                      className="h-[50vh] mx-auto z-0 object-cover object-center md:h-72"
                    />
                    <p className="z-10 w-full text-2xl pt-5 pb-3 font-bold text-white text-center md:absolute md:bottom-1 md:text-lg ">
                      {e.title}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className="w-full flex pt-5 flex-wrap justify-center items-center space-x-8 text-base leading-8 md:flex-col md:space-x-0 md:space-y-2">
            <a href="/" className="flex items-center">
              <img
                src="./images/wa.png"
                alt=""
                className="w-6 mr-2 object-contain"
              />
              +62 899-8390-395
            </a>
            <a href="/" className="flex items-center">
              <img
                src="./images/ig.png"
                alt=""
                className="w-5 mr-2 object-contain"
              />
              @auraalambypass0909
            </a>
            <a href="/" className="flex items-center">
              <img
                src="./images/fb.png"
                alt=""
                className="w-6 mr-2 object-contain"
              />
              TOKOKUE AURA ALAM
            </a>
          </div>

          <div className="absolute top-0 left-0 w-full h-full z-0 blur-xl opacity-70">
            <Slider
              {...settingbg}
              className="sliderBg"
              ref={(slider) => setSlider2(slider)}
            >
              {img.map((e, idx) => (
                <div key={idx} className="h-screen w-full overflow-hidden">
                  <img
                    src={e.img}
                    alt=""
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="py-10 min-h-[60vh] flex items-center justify-center">
          <div className="w-full">
            <p className="text-3xl font-bold pb-16 text-center">Lokasi Kami</p>

            <div className="flex px-[20%] w-full justify-between space-x-8 md:flex-col md:space-y-5 md:px-[10%] md:space-x-0">
              {address.map((e, idx) => (
                <div key={idx}>
                  <p className="font-bold">{e.title}</p>
                  <p>{e.address}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      <footer className="bg-black text-center">
        <p className="text-white py-5">&copy; Aura Alam | 2022</p>
      </footer>
    </>
  );
}
