import "./App.css";
import Soon from "./page/soon";

export default function App() {
  return (
    <div className="App">
      <Soon />
    </div>
  );
}
